/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   * Survey unique identifier.
   * @type {string}
   * @memberof Notification
   */
  readonly id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  enabled: boolean;

  /**
   *
   * @type {string}
   * @memberof Notification
   */
  vesselId: string;

  /**
   *
   * @type {string}
   * @memberof Notification
   */
  notificationEmails: string;

  /**
   *
   * @type {string}
   * @memberof Notification
   */
  reminderFrq: string;

  /**
   *
   * @type {Date}
   * @memberof Notification
   */
  readonly created?: Date;
}

export function NotificationFromJSON(json: any): Notification {
  return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Notification {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    enabled: json["enabled"],
    vesselId: json["vessel_id"],
    notificationEmails: json["notification_emails"],
    reminderFrq: json["reminder_frq"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
  };
}

export function NotificationToJSON(value?: Notification | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    enabled: value.enabled ?? false,
    vessel_id: value.vesselId,
    notification_emails: value.notificationEmails ?? "",
    reminder_frq: value.reminderFrq ?? "",
  };
}
