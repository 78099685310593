/* tslint:disable */
/* eslint-disable */
/**
 * Navigate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SafetyEquipment
 */
export interface SafetyEquipment {
  /**
   * Survey unique identifier.
   * @type {string}
   * @memberof SafetyEquipment
   */
  readonly id?: string;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  tag: string;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  docNo?: string;
  /**
   * Date equipment acquired
   * @type {Date}
   * @memberof SafetyEquipment
   */
  dateIssued: Date;
  /**
   * Date of expiry
   * @type {Date}
   * @memberof SafetyEquipment
   */
  dueDate?: Date;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  location?: string;
  /**
   *
   * @type {boolean}
   * @memberof SafetyEquipment
   */
  surveyRequired?: boolean;
  /**
   *
   * @type {Date}
   * @memberof SafetyEquipment
   */
  readonly created?: Date;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  notes?: string;
  /**
   *
   * @type {boolean}
   * @memberof SafetyEquipment
   */
  readonly overdue?: boolean;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  vesselId: string;
  /**
   *
   * @type {string}
   * @memberof SafetyEquipment
   */
  readonly customerId?: string;
}

export function SafetyEquipmentFromJSON(json: any): SafetyEquipment {
  return SafetyEquipmentFromJSONTyped(json, false);
}

export function SafetyEquipmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SafetyEquipment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    tag: json["tag"],
    docNo: !exists(json, "doc_no") ? undefined : json["doc_no"],
    dateIssued:
      !exists(json, "date_issued") || !json["date_issued"]
        ? undefined
        : new Date(json["date_issued"]),
    expiryDate:
      !exists(json, "due_date") || !json["due_date"]
        ? undefined
        : new Date(json["due_date"]),
    location: !exists(json, "location") ? undefined : json["location"],
    surveyRequired: !exists(json, "survey_required")
      ? undefined
      : json["survey_required"],
    created: !exists(json, "created") ? undefined : new Date(json["created"]),
    notes: !exists(json, "notes") ? undefined : json["notes"],
    overdue: !exists(json, "overdue") ? undefined : json["overdue"],
    vesselId: json["vessel_id"],
    customerId: !exists(json, "customer_id") ? undefined : json["customer_id"],
  };
}

export function SafetyEquipmentToJSON(value?: SafetyEquipment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    tag: value.tag,
    doc_no: value.docNo,
    date_issued:
      value.dateIssued === undefined
        ? undefined
        : value.dateIssued.toISOString(),
    due_date: !value.dueDate ? "" : value.dueDate.toISOString(),
    location: value.location,
    survey_required: value.surveyRequired,
    notes: !value.notes ? "" : value.notes,
    vessel_id: value.vesselId,
  };
}
