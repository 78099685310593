import { dispatch, fx, regEventFx } from "../../store";
import {
  AuditFromJSONTyped,
  NotificationFromJSONTyped,
} from "../../../gen/ts/models";
import { onEdit, setSubmittingFx, validateForm } from "../../forms/helpers";
import { regFormSubmitHandler } from "../../forms/handlers";
import { isCustomerAdmin, updateIn } from "../../util";
import { routeEvent, routeIds } from "../../routes/events";
import * as R from "ramda";
import { getSelectedSubItemForm, getSelectedVesselId } from "../selectors";
import { getForms, getSubCustomerId } from "forms/selectors";
import { formEvt } from "forms/events";
import { uploadToS3 } from "../../util";
import { vesselEvent } from "vessels/events";
import { getCurrentUser } from "users/selectors";

const validations: Array<{ name: string; rule: string }> = [
  { name: "reminder_frq", rule: "required" },
  { name: "notification_emails", rule: "required" },
];

regEventFx(routeIds.VESSEL_NOTIFICAIONS, ({ db }, { id }) => {
  let customerId = null;
  const user = getCurrentUser(db);
  if (!user) {
    return [];
  }
  if (isCustomerAdmin(user)) {
    customerId = getSubCustomerId();
  } else if (user) {
    customerId = user.customerId;
  }
  return [
    fx.api(["customer", "get", "many"], { perPage: 1000 }),
    fx.api(["vessel", "get", "many"], {
      perPage: 1000,
      customerId,
    }),
    fx.api(["overdue", "get", "one"], { perPage: 1000, customerId }),
    fx.db(R.assocPath(["forms", "notification"], { vessel_id: id })),
  ];
});

regFormSubmitHandler(["notification", "create"], (_, { form }) => {
  let validateResult;
  if (form.enabled) {
    validateResult = validateForm(form, validations);
  }

  if (!form.enabled || validateResult === true) {
    const payload = NotificationFromJSONTyped(form, true);
    return [
      setSubmittingFx(true),
      fx.api(["notification", "create", "one"], { payload }),
    ];
  } else {
    return [fx.dispatch(formEvt.SET_VALIDATION, { result: validateResult })];
  }
});

regFormSubmitHandler(["notification", "edit"], (_, { form }) => {
  let validateResult;
  if (form.enabled) {
    validateResult = validateForm(form, validations);
  }

  if (!form.enabled || validateResult === true) {
    const payload = NotificationFromJSONTyped(form, true);
    return [
      setSubmittingFx(true),
      fx.api(["notification", "update", "one"], { id: form.id, payload }),
    ];
  } else {
    return [fx.dispatch(formEvt.SET_VALIDATION, { result: validateResult })];
  }
});

regEventFx(
  "api/create-one-notification-success",
  ({ db }, { vesselId, id }) => {
    return [
      setSubmittingFx(false),
      fx.dispatch(routeEvent.NAV_TO, [routeIds.VESSEL_LIST]),
    ];
  }
);

regEventFx(
  "api/update-one-notification-success",
  ({ db }, { vesselId, id }) => {
    return [
      setSubmittingFx(false),
      fx.dispatch(routeEvent.NAV_TO, [routeIds.VESSEL_LIST]),
    ];
  }
);
